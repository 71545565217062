:root {
    --font-open-sans: "Open Sans";
    --primary-color: #0a4671;
    --dark-gray: #555555;
    --black: #000;
    --widget-background-color: #f8f8f8;
    --widget-color: #333333;
    --label-color: #888888;
    --off-white: #f0f0f5;
    --table-border: #ececec;
    --table-border-series: #ccc;
    --holding-summary-bg: url("https://images.ctfassets.net/hhmmh7cybzrp/67WcqVabvI1HTbEXfB38CA/37658b9bc2c851530c542741f775f17f/summary-bg-v1.png");
}
